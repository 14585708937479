<template>
  <div class="row">
    <div class="col-md-6 col-lg-4 col-xl-3 img-container mb-2" v-for="destino in this.destinos" :key="destino.id">
      
      <div class="img-cuadrada mx-auto bg-skeletongit" :style="`background-image: url('${urlBase + destino.src}')`"></div>
    
      <div class="texto-donde-ir">
        <span class="img-title">{{destino.nombre}}</span>
        <span class="border-text"></span>
        <span class="description w3-animate-bottom">{{destino.descripcion}}</span>
        <span class="text-link w3-animate-bottom">
          <router-link class="router w3-animate-bottom" :to="{path: `/destino/${destino.id}/${destino.nombre.replace(/\s/g,'-')}`}">Más información</router-link>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Config from "../configs/configs.js";

export default {
  name: "CuadriculaDestinos",
  props: {
    destinos: Array,
  },
  data() {
    return {
      urlBase: Config.API.IMG,
    };
  },
};
</script>

<style scoped>
.img-cuadrada {
  width: 18em;
  height: 18em;
  background-size: cover;
  background-repeat: no-repeat;
  filter: brightness(80%);
}

.img-title {
  text-align: center !important;
}

.texto-donde-ir {
  bottom: 15%;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
}

.texto-donde-ir span {
  color: white;
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.5em;
  line-height: 1.2em;
  padding-left: 0.1em;
  /*-webkit-text-stroke: 0.03em white;
  text-shadow:
    -1px -1px 1.3em #000000, 
    1px -1px 1.3em #000000,
    -1px 1px 1.3em #000000,
    1px 1px 1.3em #000000;*/
}

.texto-donde-ir .description {
  display: none;
  color: white;
  font-size: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
  font-weight: 300;
  text-transform: inherit;
}

.text-link a {
  display: none;
  color: white;
  text-decoration: underline;
  font-size: 0.6em;
  font-weight: bold;
}

.img-container:hover > .texto-donde-ir {
  top: 15%;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
}

.img-container:hover > .texto-donde-ir .description {
  display: block;

  /* puntos suspensivos */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.img-container:hover > .texto-donde-ir .text-link a {
  display: block;
}

.img-container:hover > .texto-donde-ir .img-title {
  position: relative;
  animation: animatebottom 0.4s;
}

.img-container:hover > .texto-donde-ir .border-text {
  position: relative;
  animation: animatebottom 0.4s;
}

.img-container:hover > .img-cuadrada {
  filter: brightness(30%);
}

.texto-donde-ir .border-text {
  border-bottom: 0.15em solid white;
  width: 3em;
  margin-top: 0.5em;
  margin-left: auto;
  margin-right: auto;
}

.w3-animate-bottom {
  position: relative;
  animation: animatebottom 0.4s;
}


@keyframes animatebottom {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

.bg-skeleton{
    background-color: #bababa;
}
</style>
