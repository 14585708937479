<template>
  <div class="mb-5">
    <div id="first-background" class="pt-3"></div>
    <Navbar />
    <div id="second-background">
      <!-- <a href="08_InfoWebSemanaSanta_FINAL.pdf"> -->
        <div id="slider-home" class="carousel slide mx-auto d-none d-md-block" data-ride="carousel">
          <div class="colors-border" id="border-div"></div>
          <div class="carousel-inner">
            <div class="carousel-item " v-for="imagen in this.imagenes" :key="imagen.orden" :class="(imagen.orden == 1)?'active':''">
              <div
                class="img-fluid d-block w-100 img-portada"
                :style="`background-image: url(${urlBase + imagen.src})`"
              />
            </div>
            <a class="carousel-control-prev" href="#slider-home" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#slider-home" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
      <!-- </a> -->
      <!-- <a href="08_InfoWebSemanaSanta_FINAL.pdf"> -->
        <div id="slider-home-m" class="carousel slide mx-auto d-block d-md-none" data-ride="carousel" v-if="this.imagenes_m">
          <div class="colors-border" id="border-div"></div>
          <div class="carousel-inner">
            <div class="carousel-item" v-for="imagen in this.imagenes_m" :key="imagen.orden" :class="(imagen.orden == 1)?'active':''">
              <div
                class="d-block w-100 img-portada"
                :style="`background-image: url(${urlBase + imagen.src})`"
              />
            </div>
            <a class="carousel-control-prev" href="#slider-home-m" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#slider-home-m" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
      <!-- </a> -->
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Config from "../configs/configs"

export default {
  name: "Portada",
  components: {
    Navbar
  },
  props: {
    imagenes: Array,
    imagenes_m: Array
  },
  data(){
    return{
      urlBase: Config.API.IMG,
    }
  }
};
</script>

<style scoped>

.img-portada{
  background-size: cover;
  /* background-position: center; */
  background-repeat: no-repeat;
  height: 22em;
  width: 100%;
}

#first-background {
  background-color: #001621;
  height: 6em;
}

#second-background {
  margin-top: -5em;
  background-color: #3080ad;
  height: 20em;
}

#slider-home {
  max-width: 90%;
  position: relative;
}

#slider-home-m {
  max-width: 90%;
  position: relative;
}

.carousel-item img {
  height: 22em;
}

/* barra de colores en la parte de abajo en el slider principal en la portada
.colors-border {
  border-bottom: 1em solid;
  border-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 5%,
      var(--azul-1) 5%,
      var(--azul-1) 20%,
      var(--azul-2) 20%,
      var(--azul-2) 35%,
      var(--azul-4) 35%,
      var(--azul-4) 50%,
      var(--verde-2) 50%,
      var(--verde-2) 65%,
      var(--verde-1) 65%,
      var(--verde-1) 80%,
      var(--naranja) 80%,
      var(--naranja) 95%,
      rgba(0, 0, 0, 0) 95%
    )
    5;
}*/

#border-div {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1em;
  z-index: 1;
}
</style>
